<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">培训信息监察</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="card-box">
          <div class="card-item">
            <div class="df">
              <span>普惠制机构</span>
              <span>{{ totalForm.phCompTotal || 0 }}</span>
            </div>

            <div class="df">
              <span>年度新增</span>
              <span>{{ totalForm.phCompYearTotal || 0 }}</span>
            </div>
          </div>
          <div class="card-item">
            <div class="df">
              <span>机构总数</span>
              <span>{{ totalForm.compTotal || 0 }}</span>
            </div>

            <div class="df">
              <span>年度新增</span>
              <span>{{ totalForm.compYearTotal || 0 }}</span>
            </div>
          </div>
          <div class="card-item">
            <div class="df">
              <span>企业总数</span>
              <span>{{ totalForm.enterpriseTotal || 0 }}</span>
            </div>
            <div class="df">
              <span>年度新增</span>
              <span>{{ totalForm.enterpriseYearTotal || 0 }}</span>
            </div>
          </div>
          <div class="card-item">
            <div class="df">
              <span>开班总数</span>
              <span>{{ totalForm.projectTotal || 0 }}</span>
            </div>
            <div class="df">
              <span>进行中</span>
              <span>{{ totalForm.projectConductTotal || 0 }}</span>
            </div>
          </div>
          <div class="card-item">
            <div class="df">
              <span>学员总数</span>
              <span>{{ totalForm.userTotal || 0 }}</span>
            </div>
            <div class="df">
              <span>当前同时在线人数</span>
              <span>{{ totalForm.userOnLineTotal || 0 }}</span>
            </div>
          </div>
          <div class="card-item">
            <div class="df">
              <span>普惠制领劵人数</span>
              <span>{{ totalForm.couponUserTotal || 0 }}</span>
            </div>
            <div class="df">
              <span>已合格人数</span>
              <span>{{ totalForm.qualifiedUserToal || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div
              title="学习进度"
              style="display: flex; align-items: center"
              class="searchboxItem"
            >
              <span class="itemLabel">学习进度:</span>
              <el-select
                clearable
                size="small"
                v-model="completeState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in LearningState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div
              title="学员信息"
              style="display: flex; align-items: center"
              class="searchboxItem"
            >
              <span class="itemLabel" style="min-width: 6rem">学员信息:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem">
              <span class="itemLabel" style="min-width: 6rem">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
             <div title="劵名称" class="searchboxItem">
              <span class="itemLabel">劵名称:</span>
              <el-input
                v-model="couponName"
                type="text"
                size="small"
                placeholder="请输入劵名称"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="行政区划" class="searchboxItem">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areaTreeList"
                clearable
                filterable
                :props="propsArea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
            <div
              title="班级信息"
              style="display: flex; align-items: center"
              class="searchboxItem"
            >
              <span class="itemLabel" style="min-width: 6rem">班级信息:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superProjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in projectList"
                  :key="item.projectId"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="学员类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">学员类型:</span>
              <el-select
                clearable
                size="small"
                v-model="userType"
                placeholder="请选择学员类型"
              >
                <el-option
                  v-for="item in studentTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="课程名称" class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="完成课时量" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">完成课时量:</span>
              <el-input
                v-model="startLessonNum"
                clearable
                @keyup.native="
                  startLessonNum = zF.oninput2(startLessonNum, 1)
                "
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="endLessonNum"
                clearable
                @keyup.native="
                  endLessonNum = zF.oninput2(endLessonNum, 1)
                "
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
            <div title="开班时间" class="searchboxItem">
              <span class="itemLabel" style="min-width: 6rem">开班时间:</span>
              <el-date-picker
                v-model="trainingTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div style="color: red">
          <span style="padding: 10px"
            >开班数量:{{ totalStudyForm.projectNum || 0 }}</span
          >
          <span style="padding: 10px"
            >学员总数:{{ totalStudyForm.userNum || 0 }}</span
          >
          <span style="padding: 10px"
            >已完成数量:{{ totalStudyForm.completeNum || 0 }}</span
          >
          <span style="padding: 10px"
            >课时数量:{{ totalStudyForm.lessonNum || 0 }}课时</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="学员类型"
                align="left"
                prop="userType"
                show-overflow-tooltip
                min-width="120"
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                min-width="180"
              />
              <el-table-column
                label="手机号码"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="班级名称"
                align="center"
                prop="projectName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="机构名称"
                align="center"
                show-overflow-tooltip
                prop="compName"
                min-width="200"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                min-width="140"
              />
              <el-table-column
                label="应修学时"
                align="left"
                prop="totalLessonNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="已修学时"
                align="left"
                prop="studyLessonNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="劵名称"
                align="left"
                prop="shanxiCouponName"
                show-overflow-tooltip
                min-width="160"
              />
              <el-table-column
                label="累计在线学习时长"
                align="left"
                prop="totalTime"
                show-overflow-tooltip
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ getTime(scope.row.totalTime) }}
                </template>
              </el-table-column>
              <el-table-column
                label="学习进度"
                align="left"
                prop="completeState"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="
                      lookArchives(
                        scope.row.userId,
                        scope.row.projectId,
                        scope.row.projectCourseId,
                        scope.row.courseId,
                        scope.row
                      )
                    "
                    >查看培训信息</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "SupervisionEnd/home",
  components: {
    PageNum,
    Empty,
  },
  mixins: [List],
  data() {
    return {
      startLessonNum:'',//完成课时开始
endLessonNum:'',//完成课时结束
      userType: "", //学员类型
      LearningState: [], //学习进度下拉数据
      completeState: "", //学习进度
      courseName: "", //课程名称
      trainingTime: "",
      compId: "", //机构名称
      userId: "", //学员id
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "", //班级Id
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      areaId: "", //行政区划Id
      couponName:'',
      areaTreeList: [], //行政区划下拉数据
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },

      params1: {},
      totalForm: {},
      stu: "mechanism",
      CompanyList: [],
      studentTypeList: [
        {
          value: "1",
          label: "企业职工",
        },
        {
          value: "2",
          label: "脱贫劳动力",
        },
        {
          value: "3",
          label: "普惠制",
        },
      ], //学员类型
      totalStudyForm: {},
    };
  },
  created() {
    this.getAreaTree(); //行政区划
    this.getLearningState(); //获取学习进度码值
    this.getJcStatistics();
    this.getTableHeight();
    this.superUserSelect();
    this.superProjectSelect();
    this.getJcStudyListStatistics();
  },
  mounted() {},
  computed: {},
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superProjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/areaUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superProjectSelect(e) {
      this.$post(
        "/biz/project/areaSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId || this.$route.query.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
  
    //行政区划
    getAreaTree() {
      this.$post("/gov/area/tree").then((ret) => {
        this.areaTreeList = ret.data;
      });
    },
    //获取学习进度码值
    getLearningState() {
      // LEARNINGSTATE
      const list = this.$setDictionary("LEARNINGSTATE", "list");
      for (const key in list) {
        this.LearningState.push({
          value: key,
          label: list[key],
        });
      }
    },
    //搜索条件参数
    getParams(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.userId) {
        params.studyUserId = this.userId;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.courseName) {
        params.courseQuery = this.courseName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainingTime) {
        params.projectStartDate = this.trainingTime[0];
        params.projectEndDate = this.trainingTime[1];
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.userType) {
        params.userType = this.userType;
      }
      if (this.couponName) {
        params.couponName = this.couponName;
      }
      if ((this.startLessonNum && !this.endLessonNum) || (!this.startLessonNum && this.endLessonNum)) {
        this.$message.warning("请补全完成课时区间");
        return false;
      } 
      if (this.startLessonNum && this.endLessonNum) {
        if (Number(this.startLessonNum) > Number(this.endLessonNum)) {
          this.$message.warning("起始课时不能大于截止课时");
          return false;
        } else {
          params.startLessonNum = this.startLessonNum;
          params.endLessonNum = this.endLessonNum;
        }
      }
      return params;
    },
    /* 获取数据 */
    getData(pageNum = 1) {
      this.doFetch({
        url: "/biz/shanxiJC/studyList",
        params: this.getParams(pageNum),
        pageNum,
      });
      this.getJcStudyListStatistics();
    },
    // 统计
    getJcStatistics() {
      this.$post("/biz/shanxiJC/jcStatistics", {}).then((res) => {
        if (res.status == "0") {
          this.totalForm = {
            ...res.data,
          };
        }
      });
    },
    // 统计
    getJcStudyListStatistics() {
      this.$post("/biz/shanxiJC/studyList/Statistics", this.getParams()).then(
        (res) => {
          if (res.status == "0") {
            console.log(res.data);
            this.totalStudyForm = {
              ...res.data,
            };
          }
        }
      );
    },
    /* 所属企业 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    //转换时分秒
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    lookArchives(userId, projectId, projectCourseId, courseId, row) {
      let userInfo = {
        projectName: row.projectName,
        userName: row.userName,
        idcard: row.idcard,
        mobile: row.mobile,
      };
      this.$router.push({
        path: "/web/shanxiSupervisionEnd/shanxiTrainingInformationSupervisionTabBox",
        query: {
          userId,
          projectId,
          projectCourseId,
          courseId,
          userInfo: JSON.stringify(userInfo),
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5 + 8) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.card-box {
  margin-bottom: 10px;
  height: 80px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  .card-item {
    padding: 10px;
    border-radius: 5px;
    min-width: 16%;
    // min-height: 150px;
    justify-content: space-around;
    cursor: pointer;
    span {
      // line-height: 30px;
      color: #fff;
    }
    div {
      justify-content: space-between;
    }
  }
  & > :nth-child(1) {
    background-image: url(../../../assets/six.png);
    background-size: 100% 100%;
  }
  & > :nth-child(2) {
    background-image: url(../../../assets/first.png);
    background-size: 100% 100%;
  }
  & > :nth-child(3) {
    background-image: url(../../../assets/second.png);
    background-size: 100% 100%;
  }
  & > :nth-child(4) {
    background-image: url(../../../assets/third.png);
    background-size: 100% 100%;
  }
  & > :nth-child(5) {
    background-image: url(../../../assets/fourth.png);
    background-size: 100% 100%;
  }
  & > :nth-child(6) {
    background-image: url(../../../assets/five.png);
    background-size: 100% 100%;
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
/deep/.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>
